import React from "react"
import { ThemeProvider } from "styled-components"
import { StaticQuery, graphql } from "gatsby"
import LayoutDefault from "../layouts/default"
import FontSection from "../components/font-section"

const IndexPage = ({ data }) => {

  console.log({ data })

  const fontSections = data.prismic.home.body && data.prismic.home.body.map((slice, index) => {
    switch (slice.type) {
      case "font_section":
        return <FontSection key={ index } input={ slice } currentSlide="01" />
    }
  })

  return (
    <ThemeProvider theme={{ mode: "light" }}>
      <LayoutDefault>
        { fontSections }
      </LayoutDefault>
    </ThemeProvider>
  )

}

// export default IndexPage

export default props => (
  <StaticQuery
    query={graphql`
    query HomeQuery {
      prismic {
        home(lang: "de-de", uid: "home") {
          page_title
          body {
            ... on PRISMIC_HomeBodyFont_section {
              type
              label
                primary {
                  font_name
                  font_info
                  buy_link_subject_line
                  buy_link_body_text
                  buy_button_background_color_initial
                  buy_button_border_color_intial
                  buy_button_background_color_hover
                  buy_button_text_color_initial
                  buy_button_text_color_hover
                }
                fields {
                  image
                }
              }
            }
          }
        }
      }
    `}
    render={ data => <IndexPage data={data} {...props} /> }
  />
)