import styled, { keyframes } from "styled-components"
import * as themeSettings from "../../styles/theme"

const wobble = keyframes`
  0% { transform: skew(0) }
  20% { transform: skew(-10deg) }
  40% { transform: skew(10deg) }
  60% { transform: skew(-5deg) }
  80% { transform: skew(5deg) }
  90% { transform: skew(2deg) }
  100% { transform: skew(0) }
`

export const ClickSlider = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`

export const ClickSliderNav = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${themeSettings.zIndexes.sliderNav};

  display: flex;
  align-items: center;
  justify-content: space-between;

  cursor: none !important;
`

export const ClickSliderNavPrev = styled.div`
  width: 50%;
  height: 100%;
`

export const ClickSliderNavNext = styled.div`
  width: 50%;
  height: 100%;
`

export const Slide = styled.picture`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    vertical-align: bottom;
  }
`

export const TouchSlider = styled.div`
  display: none;
  @media screen and (max-width: 1024px) {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* class "carousel" */
    & > div {
      height: 100%;
      /* max-height: 100%; */
      /* class "horizontalSlider" */
      & > div {
        height: 100%;
        max-height: 100%;
        /* class "carousel__slider-tray-wrapper" */
        & > div {
          height: 100%;
          max-height: 100%;
          /* class "sliderTray" */
          & > ul {
            height: 100%;
            max-height: 100%;
            display: flex;
            transition: transform 0.3s ease;
            & > li {
              height: 100%;
              max-height: 100%;
              /* class "slideInner" */
              & > div {
                height: 100%;
                & > img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  vertical-align: bottom;
                }
              }
            }
          }
        }
      }
    }
  }
`

export const Footer = styled.div`
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: ${themeSettings.margins.page};

  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  z-index: ${themeSettings.zIndexes.sliderFooter};

  @media screen and (max-width: 414px) {
    flex-direction: column;
    align-items: flex-start;
    bottom: ${themeSettings.mobileMargins.page};
    padding: ${themeSettings.mobileMargins.page};
  }
`

export const InfoLinkBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: ${ props => props.bgColorHover };
  z-index: -1;
  transition: 0.4s ease-out;
  @media screen and (max-width: 1024px) {
    display: none;
  }
`

export const InfoLinkBackgroundInitial = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${ props => props.bgColorInitial };
  z-index: -2;
  @media screen and (max-width: 1024px) {
    display: none;
  }
`

export const InfoLink = styled.div`
  position: relative;
  padding: 12px 48px;
  border-radius: 26px;
  /* background-color: ${ props => props.bgColorInitial }; */
  border: solid 2px ${ props => props.borderColorInitial };
  overflow: hidden;
  cursor: pointer;
  transition: all 0.4s ease-out;

  span {
    z-index: 2;
    color: ${ props => props.textColorInitial };
    transition: all 0.4s ease-out;
  }

  @media screen and (min-width: 1025px) {
    &:hover {
      border: solid 2px ${ props => props.borderColorHover };
      background-color: transparent;
      span {
        color: ${ props => props.textColorHover };
      }
      ${InfoLinkBackground} {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    position: initial;
    left: 100%;
    transform: translate(0);
    background-color: ${ props => props.bgColorInitial };
  }

  @media screen and (max-width: 414px) {
    box-sizing: border-box;
    text-align: center;
    padding: 10px 26px;
    width: 100%;
  }
`

export const BuyLink = styled.a`
  position: absolute;
  bottom: ${themeSettings.margins.page};
  padding: 12px 48px;
  border-radius: 26px;
  border: solid 2px #FFF;
  color: #FFF;
  overflow: hidden;
  span {
    z-index: 2;
    color: ${ props => props.textColorInitial };
    transition: all 0.4s ease-out;
  }
  @media screen and (max-width: 414px) {
    box-sizing: border-box;
    text-align: center;
    padding: 10px 0;
    bottom: 24px;
    width: calc(100vw - (${themeSettings.mobileMargins.page} * 2));
  }
  @media screen and (min-width: 1024px) {
    &:hover {
      border: solid 2px ${ props => props.borderColorHover };
      span {
        color: ${ props => props.textColorHover };
      }
      ${InfoLinkBackground} {
        width: 100%;
      }
    }
  }
`

export const Count = styled.div`
  box-sizing: border-box;
  position: absolute;
  left: 0;
  width: 100%;
  height: 16px;
  z-index: 999;

  text-align: right;

  pointer-events: none;

  padding: ${themeSettings.margins.page};

  display: flex;
  align-items: center;
  justify-content: flex-end;

  transform: translateY(-5px);

  @media screen and (max-width: 1024px) {
    display: none;
  }

`

export const DividerContainer = styled.div`
  height: 16px;
  width: 22px;
  margin: 0 4px;

  display: flex;
  align-items: center;
  justify-content: center;

  transform: skew(-20deg) translateY(1px);

  @media screen and (max-width: 1024px) {
    /* height: 18px; */
    transform: skew(-20deg) translateY(1px);
  }

  @media screen and (max-width: 1024px) {
    /* height: 14px; */
    transform: skew(-20deg) translateY(1px);
    margin: 0 2px;
  }
`

export const Divider = styled.div`
  height: 100%;
  width: 2px;
  background-color: #000;

  transition: transform 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
`

export const Panel = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: ${ props => props.isActive ? '50%' : 0 };
  background-color: #000;
  z-index: 99998;
  color: #FFF;
  /* padding: calc(${themeSettings.margins.page} * 1.9) 20vw ${themeSettings.margins.page} ${themeSettings.margins.page}; */
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  @media screen and (max-width: 1024px) {
    width: ${props => props.isActive ? '100%' : 0};
  }
`

export const PanelContent = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 50vw;
  height: 100%;
  padding: calc(${themeSettings.margins.page} * 1.9) 20vw ${themeSettings.margins.page} ${themeSettings.margins.page};

  h2 {
    margin-bottom: 10px;
  }

  @media screen and (max-width: 1024px) {
    width: 100vw;
    padding: calc(${themeSettings.margins.page} * 1.9) 8vw ${themeSettings.margins.page} ${themeSettings.margins.page};
  }
  @media screen and (max-width: 414px) {
    width: 100vw;
    padding: ${themeSettings.mobileMargins.page};
  }
`

export const PanelCloseLink = styled.p`
  display: inline-block;
  position: absolute;
  right: ${themeSettings.margins.page};
  top: calc(${themeSettings.margins.page} * 1.9);
  cursor: pointer;

  @media screen and (max-width: 414px) {
    top: ${themeSettings.mobileMargins.page};
    right: ${themeSettings.mobileMargins.page};
  }

  a {
    color: #fff !important;
  }
  &:hover {
    animation: 0.8s ${wobble} ease-out;
  }
`