import styled from "styled-components"

export const Arrow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 29px;
  height: 20px;
  z-index: 9999;
  opacity: 0;
  transform-origin: center;
  transform: translateX(-50%);
  pointer-events: none;

  @media screen and (max-width: 1024px) {
   display: ${props => props.mobileVisibility === false ? "none" : "block"} 
  }
`