import React, { useState } from "react"
import { CarouselProvider, Slider, Slide } from "pure-react-carousel"
import Arrow from "../arrow"
import Panel from './panel'
import * as Styled from "./styled"

const SpecimenSlider = props => {

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)
  const [currentZIndex, setCurrentZIndex] = useState(1)
  const [cursorVisiblity, setCursorVisibility] = useState(false)
  const [cursorPosition, setCursorPosition] = useState({
    x: 0,
    y: 0
  })
  const [cursorRotation, setCursorRotation] = useState(0)
  const [dividerRotation, setDividerRotation] = useState(0)
  const [panelIsActive, setPanelIsActive] = useState(false)

  const totalSlidesCount = props.input.fields.length
  const totalSlidesOutput = props.input.fields.length < 9 ? `0${props.input.fields.length}` : props.input.fields.length
  const slideAltText = `${props.input.primary.font_name["0"].text} Specimen, by SFB Fonts`

  const showCursor = () => {
    setCursorVisibility(true)
  }

  const hideCursor = () => {
    setCursorVisibility(false)
  }

  const rotateCursor = () => {
    setCursorRotation(180)
  }

  const unrotateCursor = () => {
    setCursorRotation(0)
  }

  const cursorMove = event => {
    setCursorPosition({
      x: event.pageX,
      y: event.pageY - window.scrollY
    })
  }

  const rotateDivider = () => {
    setDividerRotation(dividerRotation + parseInt(180))
  }

  const goToNextSlide = () => {
    if (currentSlideIndex === totalSlidesCount - 1) {
      return setCurrentSlideIndex(0), setCurrentZIndex(1), rotateDivider()
    }
    setCurrentSlideIndex(currentSlideIndex + 1)
    setCurrentZIndex(currentZIndex + 1)
    console.log("currentSlideIndex: " + currentSlideIndex)
    console.log("currentZIndex: " + currentZIndex)
    rotateDivider()
  }

  const goToPrevSlide = () => {
    if (currentSlideIndex === 0) {
      return setCurrentSlideIndex(totalSlidesCount - 1), rotateDivider()
    }
    setCurrentSlideIndex(currentSlideIndex - 1)
    setCurrentZIndex(currentZIndex + 1)
    rotateDivider()
  }

  const openPanel = () => {
    setPanelIsActive(true)
  }

  const closePanel = () => {
    setPanelIsActive(false)
  }

  const slideCurrent = currentSlideIndex < 9 ? `0${currentSlideIndex + 1}` : currentSlideIndex + 1

  const slides = props.input.fields.map((item, index) => {
    return (
      <Styled.Slide
        style={{ zIndex: index === currentSlideIndex ? currentZIndex : currentZIndex - 1 }}
      >
        <img
          src={ item.image.xlarge.url }
          alt={ slideAltText }
        />
      </Styled.Slide>
    )
  })

  const touchSlides = props.input.fields.map((item, index) => {
    return (
      <Slide index={ index }>
        <img
          src={ item.image.xlarge.url }
          alt={ slideAltText }
        />
      </Slide>
    )
  })

  return (
    <>
      <Panel
        closePanel={ closePanel }
        input={ props.input }
        isActive={ panelIsActive }
      />
      <Styled.ClickSlider>
        <Arrow
          visibility={ cursorVisiblity ? 1 : 0 }
          positionY={ cursorPosition.y + "px" }
          positionX={ cursorPosition.x + "px" }
          rotation={ cursorRotation }
          mobileVisibility={ false }
        />
        <Styled.ClickSliderNav
          onMouseOver={ showCursor }
          onMouseOut={ hideCursor }
          onMouseMove={ cursorMove }
        >
          <Styled.ClickSliderNavPrev
            onMouseOver={ rotateCursor }
            onMouseOut={ unrotateCursor }
            onClick={ goToPrevSlide }
          />
          <Styled.ClickSliderNavNext onClick={ goToNextSlide } />
        </Styled.ClickSliderNav>
        { slides }
      </Styled.ClickSlider>
      <Styled.TouchSlider>
        <CarouselProvider
          naturalSlideWidth={ 100 }
          naturalSlideHeight={ 100 }
          totalSlides={ totalSlidesOutput }
          infinite={ true }
          dragStep={ 1 }
        >
          <Slider>
            { touchSlides }
          </Slider>
        </CarouselProvider>
      </Styled.TouchSlider>
      <Styled.Footer>
          <Styled.InfoLink
            onClick={ openPanel }
            bgColorInitial={ props.input.primary.buy_button_background_color_initial ? props.input.primary.buy_button_background_color_initial : 'transparent' }
            borderColorInitial={ props.input.primary.buy_button_border_color_intial }
            textColorInitial={ props.input.primary.buy_button_text_color_initial }
            bgColorHover={ props.input.primary.buy_button_background_color_hover }
            borderColorHover={ props.input.primary.buy_button_background_color_hover }
            textColorHover={ props.input.primary.buy_button_text_color_hover }
          >
            <span>Info & pricing</span>
            <Styled.InfoLinkBackground
              bgColorHover={ props.input.primary.buy_button_background_color_hover }
            />
            <Styled.InfoLinkBackgroundInitial
              bgColorInitial={ props.input.primary.buy_button_background_color_initial ? props.input.primary.buy_button_background_color_initial : 'transparent' }
            />
          </Styled.InfoLink>
          <Styled.Count>
            <h2>{ slideCurrent }</h2>
            <Styled.DividerContainer>
              <Styled.Divider style={{ transform: `rotate(${dividerRotation}deg)` }} />
            </Styled.DividerContainer>
            <h2>{ totalSlidesOutput }</h2>
          </Styled.Count>
        </Styled.Footer>
      </>
  )
}

export default SpecimenSlider