import styled from "styled-components"
import * as themeSettings from "../../styles/theme"

export const Section = styled.section`
  box-sizing: border-box;
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: calc(100vh - ${themeSettings.elementHeights.navDesktop}px);
  padding: ${themeSettings.margins.page};
  &:nth-child(1) {
    margin-top: ${themeSettings.elementHeights.navDesktop}px;
    @media screen and (max-width: 414px) {
      margin-top: ${themeSettings.elementHeights.navMobile}px;
    }
  }

  @media screen and (max-width: 1024px) {
    height: 60vh;
  }

  @media screen and (max-width: 414px) {
    padding: ${themeSettings.mobileMargins.page};
  }
`