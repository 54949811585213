import React from "react"
import SpecimenSlider from "../slider"
import * as Styled from "./styled"

const FontSection = props => {

  return (
    <Styled.Section>
      <SpecimenSlider {...props} />
    </Styled.Section>
  )
}

export default FontSection