import React from "react"
import { RichText } from "prismic-reactjs"
import * as Styled from "./styled"

const Panel = ({ input, isActive, closePanel }) => {
  return (
    <Styled.Panel isActive={isActive}>
      <Styled.PanelContent>
        <Styled.PanelCloseLink onClick={closePanel}>
          <span>Close</span>
        </Styled.PanelCloseLink>
        <h2>{`${input.primary.font_name["0"].text} `}</h2>
        <div>{RichText.render(input.primary.font_info)}</div>
        <Styled.BuyLink
          href={`mailto:invoice@studiofabiobiesel.com?subject=${
            input.primary.buy_link_subject_line[0].text
          }&body=${
            input.primary.buy_link_body_text === null
              ? " "
              : input.primary.buy_link_body_text[0].text
          }`}
          borderColorHover={input.primary.buy_button_background_color_hover}
          textColorHover={input.primary.buy_button_text_color_hover}
        >
          <span>Buy it!</span>
          <Styled.InfoLinkBackground
            bgColorHover={input.primary.buy_button_background_color_hover}
          />
        </Styled.BuyLink>
      </Styled.PanelContent>
    </Styled.Panel>
  )
}

export default Panel
